import React from 'react';
import { Link } from 'gatsby';
import Seo from '../components/SEO';
import useSound from 'use-sound';
import Switch from '../sounds/switch.mp3';
import PaperPlane from '../svg/paperPlane.svg';

function ThankYouPage() {
  const [pop] = useSound(Switch);
  return (
    <React.Fragment>
      <Seo pageTitle="success" />
      <div className="mt-16 mb-3 md:mt-20 lg:mt-12mt-16 lg:mt-12">
        <div className="flex flex-col items-center py-8 text-primary">
          <PaperPlane className="w-1/3" />
        </div>
        <h1 className="mb-3 text-4xl font-bold text-center md:text-5xl text-primary">
          addy saved
        </h1>
      </div>
      <p className="w-4/6 py-4 pb-16 m-auto text-base text-center md:w-3/6 text-primary">
        we'll be in touch as and when things worthwhile come up.
        <br />
        <br />
        peace.
      </p>
      <div className="flex flex-col justify-center mx-16 my-16 lg:mx-64">
        <Link className="secondary-btn" to="/" onClick={pop}>
          <b>Take me home</b>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default ThankYouPage;
